//fix for annotation timeline chart #2. It breaks layout if width does not specified explicitly
let chartWidth = window.innerWidth * 0.5;

export default {
  chart1: {
    type: "ColumnChart",
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: "75%",
        height: "80%"
      },
      legend: {
        //       position: 'none'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0
    }
  },
  chart2: {
    type: "ColumnChart",
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: "75%",
        height: "80%"
      },
      legend: {
        //       position: 'none'
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0
    }
  },
  chart3: {
    googleRef: undefined,
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      width: chartWidth,
      height: 400,

      chartArea: {
        width: "80%",
        height: "60%"
      },
      vAxis: {
        viewWindow: {
          // max: 200,
        }
      },
      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      },
      thickness: 2,
      displayZoomButtons: false
    }
  },
  chart4: {
    type: "BarChart",
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: "65%",
        height: "80%"
      },
      legend: {
        position: "left"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0
    }
  },
  chart5: {
    type: "BarChart",
    pagination: {
      value: 1,
      total: 0
    },
    isBarChart: false,
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: "65%",
        height: "80%"
      },
      legend: {
        position: "left"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0
    }
  }
};
