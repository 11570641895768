<template>
  <div class="animated fadeIn">
    <b-card v-show="true">
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            :load-dictionaries="loadDictionaries"
            dataset-name="product-reports/wh-product-details"
            :show-labels="false"
            :compact="true"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            :loaded="filteringPanel.loaded"
            @dict-loaded="onFilteringPanelLoad"
            @change="onFilteringPanelChange"
            @search="getData(0)"
            @reset="onFilteringPanelReset"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="12">
          <b-popover target="chart1-help" triggers="hover" placement="right">
            <span v-html="popoverTemplateAllMetrics" />
          </b-popover>
          <g-chart-custom
            ref="chart1"
            :title="`Product sales (${selectedMetric().label})`"
            subtitle="Comparison of sales by periods"
            :type="charts.chart1.type"
            :loading="charts.chart1.loading"
            :data="charts.chart1.chartData"
            :options="charts.chart1.chartOptions"
          >
            <template #legend>
              <i id="chart1-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel1"
                      mode="server"
                      dataset-name="product-details/wh-products-sales-chart-1"
                      :show-labels="false"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel1.filters"
                      :loaded="filteringPanel1.loaded"
                      @search="getData(1)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col lg="2" sm="12" md="12">
                  <b-pagination
                    v-model="charts.chart1.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart1.pagination.total"
                    :per-page="10"
                    @input="updateChart1"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart1.isBarChart"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isBarChart = !charts.chart1.isBarChart;
                      charts.chart1.type = 'BarChart';
                      updateChart1();
                    "
                  >
                    <font-awesome-icon icon="chart-line" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart1.isBarChart"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isBarChart = !charts.chart1.isBarChart;
                      charts.chart1.type = 'ColumnChart';
                      updateChart1();
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <g-chart-custom
            ref="chart2"
            :title="`Total sales comparison (${selectedMetric().label})`"
            subtitle="Comparison of sales by periods"
            :default-mode="charts.chart2.isDefaultMode"
            :type="charts.chart2.type"
            :loading="charts.chart2.loading"
            :data="charts.chart2.chartData"
            :options="charts.chart2.chartOptions"
          >
            <template #legend>
              <i id="chart2-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    Compare to:
                    <filtering-panel
                      ref="filteringPanel2"
                      mode="server"
                      dataset-name="product-details/wh-product-details-chart-2"
                      :show-labels="false"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel2.filters"
                      :loaded="filteringPanel2.loaded"
                      @search="getData(2)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-form-checkbox
                    name="checkboxGroupByYears2"
                    inline
                    switch
                    v-model="charts.chart2.groupByYearsView"
                    @input="updateChart2"
                  >
                    Group by years
                  </b-form-checkbox>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart2.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isColumnType = !charts.chart2.isColumnType;
                      charts.chart2.type = 'LineChart';
                    "
                  >
                    <font-awesome-icon icon="chart-line" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart2.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isColumnType = !charts.chart2.isColumnType;
                      charts.chart2.type = 'ColumnChart';
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div ref="chart3container">
            <g-chart-custom
              id="chart3"
              ref="chart3"
              :title="`Total sales (${selectedMetric().label})`"
              :show-tabs="true"
              :default-mode="charts.chart3.isDefaultMode"
              subtitle=""
              @ready="onTotalSalesReady"
              :loading="charts.chart3.loading"
              type="AnnotationChart"
              :settings="{ packages: ['annotationchart'] }"
              :data="charts.chart3.chartData"
              :options="charts.chart3.chartOptions"
            >
              <template #legend>
                <i id="chart3-help" class="fa fa-question-circle" />
              </template>
              <template #filter>
                <filtering-panel
                  ref="filteringPanel3"
                  mode="server"
                  dataset-name="product-details/wh-product-sales-chart-3"
                  :show-labels="false"
                  :compact="true"
                  :hide-reset-button="true"
                  :filters="filteringPanel3.filters"
                  :loaded="filteringPanel3.loaded"
                  @search="getData(3)"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col v-show="charts.chart3.isDefaultMode">
                    <b-button-group
                      v-show="charts.chart3.isDefaultMode"
                      class="mb-2"
                    >
                      <b-button
                        variant="outline-primary"
                        @click="onChart3ZoomClick(3)"
                      >
                        3 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart3ZoomClick(6)"
                      >
                        6 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart3ZoomClick(12)"
                      >
                        1 year
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart3ZoomClick(24)"
                      >
                        2 years
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart3ZoomClick(60)"
                      >
                        5 years
                      </b-button>
                    </b-button-group>
                  </b-col>
                  <b-col v-show="!charts.chart3.isDefaultMode">
                    <b-form-checkbox
                      name="checkboxGroupByYears"
                      inline
                      switch
                      v-model="chart3Table.groupByYearsView"
                      @input="updateChart3Table"
                    >
                      Group by years
                    </b-form-checkbox>
                    <b-form-checkbox
                      name="checkboxYTD"
                      inline
                      v-show="chart3Table.groupByYearsView"
                      v-model="chart3Table.YTD"
                      @input="updateChart3Table"
                    >
                      Limit to YTD
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart3.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3.isDefaultMode = !charts.chart3
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart3.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3.isDefaultMode = !charts.chart3
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="chart3Table.name"
                  :loading="chart3Table.isLoading"
                  :data="chart3Table.dataSet"
                  :options="chart3Table.options"
                  @mounted="onChart3TableMount"
                />
              </template>
            </g-chart-custom>
          </div>
        </b-col>
      </b-row>
      <p />
      <b-row>
        <b-col lg="6">
          <b-popover target="chart4-help" triggers="hover" placement="right">
            <span v-html="popoverTemplateAllMetrics" />
          </b-popover>
          <g-chart-custom
            ref="chart4"
            :default-mode="charts.chart4.isDefaultMode"
            :title="`Sales by markets`"
            :subtitle="charts.chart4.subtitle"
            :type="charts.chart4.type"
            :loading="charts.chart4.loading"
            :data="charts.chart4.chartData"
            :options="charts.chart4.chartOptions"
          >
            <template #legend>
              <i id="chart4-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel4"
                      mode="server"
                      dataset-name="product-details/wh-products-sales-chart-4"
                      :show-labels="false"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel4.filters"
                      :loaded="filteringPanel4.loaded"
                      @search="getData(4)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col
                  lg="6"
                  sm="12"
                  md="12"
                  v-show="charts.chart4.isDefaultMode"
                >
                  <b-pagination
                    v-model="charts.chart4.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart4.pagination.total"
                    :per-page="10"
                    @input="updateChart4"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-button-group>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart4.isColumnType = false;
                        charts.chart4.type = 'PieChart';
                        charts.chart4.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart4.isColumnType = true;
                        charts.chart4.type = 'BarChart';
                        charts.chart4.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-bar" />
                    </b-button>

                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="charts.chart4.isDefaultMode = false"
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart4Table.name"
                :loading="chart4Table.isLoading"
                :data="chart4Table.dataSet"
                :options="chart4Table.options"
                @mounted="onChart4TableMount"
              />
            </template>
          </g-chart-custom>
        </b-col>
        <b-col lg="6">
          <b-popover target="chart5-help" triggers="hover" placement="right">
            <span v-html="popoverTemplateAllMetrics" />
          </b-popover>
          <g-chart-custom
            ref="chart5"
            :default-mode="charts.chart5.isDefaultMode"
            :title="`Top 100 accounts sales`"
            :subtitle="charts.chart5.subtitle"
            :type="charts.chart5.type"
            :loading="charts.chart5.loading"
            :data="charts.chart5.chartData"
            :options="charts.chart5.chartOptions"
          >
            <template #legend>
              <i id="chart5-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel5"
                      mode="server"
                      dataset-name="product-details/wh-products-sales-chart-5"
                      :show-labels="false"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel5.filters"
                      :loaded="filteringPanel5.loaded"
                      @search="getData(5)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col
                  lg="6"
                  sm="12"
                  md="12"
                  v-show="charts.chart5.isDefaultMode"
                >
                  <b-pagination
                    v-model="charts.chart5.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart5.pagination.total"
                    :per-page="10"
                    @input="updateChart5"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-button-group>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isColumnType = false;
                        charts.chart5.type = 'PieChart';
                        charts.chart5.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isColumnType = true;
                        charts.chart5.type = 'BarChart';
                        charts.chart5.isDefaultMode = true;
                      "
                    >
                      <font-awesome-icon icon="chart-bar" />
                    </b-button>
                    <b-button
                      class="mb-2"
                      variant="outline-primary"
                      @click="charts.chart5.isDefaultMode = false"
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart5Table.name"
                :loading="chart5Table.isLoading"
                :data="chart5Table.dataSet"
                :options="chart5Table.options"
                @mounted="onChart5TableMount"
              />
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";
import GChartCustom from "@/components/GChartCustom";
import TableCustom from "@/components/TableCustom";

import fp from "./filters";
import charts from "./charts";
import tables from "./tables";

export default {
  name: "Overview",
  components: {
    FilteringPanel,
    GChartCustom,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      activeTab: 0,
      filteringPanel: fp.filteringPanel,
      filteringPanel1: fp.filteringPanel1,
      filteringPanel2: fp.filteringPanel2,
      filteringPanel3: fp.filteringPanel3,
      filteringPanel4: fp.filteringPanel4,
      filteringPanel5: fp.filteringPanel5,
      popoverTemplate:
        "<ul> <li>Selection period: The user selected date range</li> </ul>",
      popoverTemplateAllMetrics:
        "<ul> <li>Selection period: The user selected date range</li><li>Reference period: A time period of equal length immediately prior to the selected date range</li> <li>Same period one year prior: The selected date range one year prior to user input</li> </ul>",
      charts: charts,
      chart1Table: tables.chart1Table,
      chart2Table: tables.chart2Table,
      chart3Table: tables.chart3Table,
      chart4Table: tables.chart4Table,
      chart5Table: tables.chart5Table,
      districts: []
    };
  },
  computed: {},
  mounted() {
    this.updateDateRanges();
  },
  methods: {
    onFilteringPanelLoad() {},
    selectedMetric() {
      if (
        !this.$refs.filteringPanel ||
        !this.$refs.filteringPanel.selected.metric
      )
        return this.filteringPanel.filters.find(f => f.name === "metric")
          .options[0];

      return this.$refs.filteringPanel.selected.metric;
    },

    onFilteringPanelReset() {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel1.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel2.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel3.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel4.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel5.resetFilters({
        resetStorage: true
      });

      this.updateDateRanges();
    },
    onFilteringPanelChange(payload) {
      this.updateDateRanges(payload);
    },
    updateDateRanges() {
      let filter = this.$refs.filteringPanel.selected;

      //if user values are not defined then set date range to 1 year prior period selected at the top
      //https://gitlab.com/koval-projects/koval-crm/-/issues/211#note_356827025

      let defaultPeriod1 = {
        startDate: moment(filter.period.startDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.period.endDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD")
      };
      let defaultPeriod2 = {
        startDate: moment(filter.period.startDate)
          .subtract(2, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.period.endDate)
          .subtract(2, "year")
          .format("YYYY-MM-DD")
      };

      //set default period if storage was empty
      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs["period"][0].setValue(defaultPeriod1, {
          triggerChanged: false
        });
      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs["period2"][0].setValue(
          defaultPeriod2,
          {
            triggerChanged: false
          }
        );

      this.$refs.filteringPanel2.filtersSaved2Storage ||
        this.$refs.filteringPanel2.$refs["period"][0].setValue(defaultPeriod1, {
          triggerChanged: false
        });
      this.$refs.filteringPanel2.filtersSaved2Storage ||
        this.$refs.filteringPanel2.$refs["period2"][0].setValue(
          defaultPeriod2,
          {
            triggerChanged: false
          }
        );
    },
    async loadDictionaries() {
      let self = this;

      //this.$refs.filteringPanel.isLoading = true; Will be set to loading state inside mounted method
      this.$refs.filteringPanel1.isLoading = true;
      this.$refs.filteringPanel2.isLoading = true;
      this.$refs.filteringPanel3.isLoading = true;
      this.$refs.filteringPanel4.isLoading = true;
      this.$refs.filteringPanel5.isLoading = true;

      let response = await self.$api.get("dictionaries/territories");

      let territories = response.map(u => ({
        id: u.ID,
        label: u.Name
      }));

      response = await self.$api.get("products/finished-products");

      let products = response.map(u => ({
        id: u.id,
        label: u.name
      }));

      response = await self.$api.post("dictionaries/countries/mapped", {
        ignore_restrictions: false
      });

      self.districts = response;

      self.filteringPanel.districts = self.districts;

      response = await self.$api.get("dictionaries/markets");

      let markets = response.map(u => ({
        id: u.id,
        label: u.name
      }));

      self.filteringPanel.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel1.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel2.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel3.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel4.filters.find(
        f => f.name === "territory"
      ).options = territories;
      self.filteringPanel5.filters.find(
        f => f.name === "territory"
      ).options = territories;

      self.filteringPanel.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel1.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel2.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel3.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel4.filters.find(
        f => f.name === "market"
      ).options = markets;
      self.filteringPanel5.filters.find(
        f => f.name === "market"
      ).options = markets;

      self.filteringPanel.filters.find(
        f => f.name === "product"
      ).options = products;
      self.filteringPanel1.filters.find(
        f => f.name === "product"
      ).options = products;
      self.filteringPanel2.filters.find(
        f => f.name === "product"
      ).options = products;
      self.filteringPanel3.filters.find(
        f => f.name === "product"
      ).options = products;
      self.filteringPanel4.filters.find(
        f => f.name === "product"
      ).options = products;
      self.filteringPanel5.filters.find(
        f => f.name === "product"
      ).options = products;

      this.$refs.filteringPanel1.isLoading = false;
      this.$refs.filteringPanel2.isLoading = false;
      this.$refs.filteringPanel3.isLoading = false;
      this.$refs.filteringPanel4.isLoading = false;
      this.$refs.filteringPanel5.isLoading = false;

      this.getData(0);
    },
    async getData(_mode) {
      let self = this;

      //debugger

      let topFilter = this.$refs.filteringPanel.selected;
      let filter1 = this.$refs.filteringPanel1.selected;
      let filter2 = this.$refs.filteringPanel2.selected;
      let filter3 = this.$refs.filteringPanel3.selected;
      let filter4 = this.$refs.filteringPanel4.selected;
      let filter5 = this.$refs.filteringPanel5.selected;

      let filterRequest = Object.assign({}, topFilter);

      if (_mode === 0 || _mode === 1) {
        filterRequest.period2 = filter1.period;
        filterRequest.period3 = filter1.period2;

        //overwrite top level filter if exists
        !filter1.product ||
          (filterRequest.product = Object.assign({}, filter1.product));
        !filter1.territory ||
          (filterRequest.territory = Object.assign({}, filter1.territory));
        !filter1.market ||
          (filterRequest.market = Object.assign({}, filter1.market));

        this.charts.chart1.loading = true;
        //this.charts.chart2.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData("products/wh-product/details", filterRequest)
          .then(response => {
            self.drawChart1(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 2) {
        filterRequest.period2 = filter2.period;
        filterRequest.period3 = filter2.period2;

        //overwrite top level filter if exists
        !filter2.product ||
          (filterRequest.product = Object.assign({}, filter2.product));
        !filter2.territory ||
          (filterRequest.territory = Object.assign({}, filter2.territory));
        !filter2.market ||
          (filterRequest.market = Object.assign({}, filter2.market));

        this.charts.chart2.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData(
            "products/wh-product/sales-comparison",
            filterRequest
          )
          .then(response => {
            self.drawChart2(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 3) {
        //overwrite top level filter if exists
        !filter3.product ||
          (filterRequest.product = Object.assign({}, filter3.product));
        !filter3.territory ||
          (filterRequest.territory = Object.assign({}, filter3.territory));
        !filter3.market ||
          (filterRequest.market = Object.assign({}, filter3.market));

        this.charts.chart3.loading = true;

        this.$api.data
          .requestReportData(
            "products/wh-product/total-sales-timeline",
            filterRequest
          )
          .then(response => {
            self.drawChart3(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 4) {
        //filterRequest.period2 = filter4.period
        //filterRequest.period3 = filter2.period2

        //overwrite top level filter if exists
        !filter4.product ||
          (filterRequest.product = Object.assign({}, filter4.product));
        !filter4.territory ||
          (filterRequest.territory = Object.assign({}, filter4.territory));
        !filter4.market ||
          (filterRequest.market = Object.assign({}, filter5.market));

        this.charts.chart4.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData(
            "products/wh-product/sales-by-markets",
            filterRequest
          )
          .then(response => {
            self.drawChart4(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 5) {
        //filterRequest.period2 = filter5.period
        //filterRequest.period3 = filter2.period2

        //overwrite top level filter if exists
        !filter5.product ||
          (filterRequest.product = Object.assign({}, filter5.product));
        !filter5.territory ||
          (filterRequest.territory = Object.assign({}, filter5.territory));
        !filter5.market ||
          (filterRequest.market = Object.assign({}, filter5.market));

        this.charts.chart5.loading = true;

        //this.charts.chart1.subtitle = `Percent change in ${this.selectedMetric().label} compared to ${moment(filter1.period.startDate).format('YYYY-MM-DD')} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}`

        this.$api.data
          .requestReportData(
            "products/wh-product/sales-by-accounts",
            filterRequest
          )
          .then(response => {
            self.drawChart5(response, filterRequest);
          });
      }
    },
    drawChart1: function(response) {
      this.charts.chart1.loading = false;

      if (response.length === 0) return;

      this.charts.chart1.rawData = response;

      this.updateChart1();
    },
    updateChart1() {
      let data = this.charts.chart1.rawData;

      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      let filter = Object.assign({}, this.$refs.filteringPanel.selected);
      filter.period2 = Object.assign(
        {},
        this.$refs.filteringPanel1.selected.period
      );
      filter.period3 = Object.assign(
        {},
        this.$refs.filteringPanel1.selected.period2
      );

      let headers = [
        [
          "Product",
          `${moment(filter.period.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period2.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period2.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period3.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period3.endDate
          ).format("YYYY-MM-DD")}`
        ]
      ];

      data = [
        ...data.map(item => {
          return [
            item.productname,
            parseFloat(item[`${metric}1`] || 0),
            parseFloat(item[`${metric}2`] || 0),
            parseFloat(item[`${metric}3`] || 0)
          ];
        })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart1.pagination.total = data.length;
      data = data.slice(
        this.charts.chart1.pagination.value * 10 - 10,
        this.charts.chart1.pagination.value * 10
      );

      this.charts.chart1.chartData = headers.concat(data);
    },
    drawChart2: function(response) {
      this.charts.chart2.loading = false;

      if (response.length === 0) return;

      this.charts.chart2.rawData = response;

      this.updateChart2();
    },

    updateChart2() {
      let data = [];

      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      let filter = Object.assign({}, this.$refs.filteringPanel.selected);
      filter.period2 = Object.assign(
        {},
        this.$refs.filteringPanel2.selected.period
      );
      filter.period3 = Object.assign(
        {},
        this.$refs.filteringPanel2.selected.period2
      );

      let headers = [
        [
          "Period",
          `${moment(filter.period.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period2.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period2.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period3.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period3.endDate
          ).format("YYYY-MM-DD")}`
        ]
      ];

      let groupedData = [];

      if (!this.charts.chart2.groupByYearsView) {
        this.charts.chart2.rawData.forEach(t => {
          //let mm = groupedData.find(g => g['x'] === parseInt(t.mm))
          let mname = groupedData.find(g => g["x"] === t.mname);

          if (!mname) {
            let o = {};

            //o['x'] = parseInt(t.mm)
            o["x"] = t.mname;

            o[`${metric}${t.period}`] = parseFloat(t[metric]);

            groupedData.push(o);
          } else {
            if (!mname[`${metric}${t.period}`])
              mname[`${metric}${t.period}`] = 0;

            mname[`${metric}${t.period}`] += parseFloat(t[metric]);
          }
        });

        data = [
          ...groupedData.map(item => {
            return [
              item.x,
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ];
          })
        ];
      } else {
        this.charts.chart2.rawData.forEach(t => {
          //let yy = groupedData.find(g => g['x'] === parseInt(t.yy))
          let yy = groupedData.find(g => g["x"] === t.yy);

          if (!yy) {
            let o = {};

            //o['x'] = parseInt(t.yy)
            o["x"] = t.yy;

            o[`${metric}${t.period}`] = parseFloat(t[metric]);

            groupedData.push(o);
          } else {
            if (!yy[`${metric}${t.period}`]) yy[`${metric}${t.period}`] = 0;

            yy[`${metric}${t.period}`] += parseFloat(t[metric]);
          }
        });

        data = [
          ...groupedData.map(item => {
            return [
              item.x,
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ];
          })
        ];
      }

      data = data.sort(function(a, b) {
        return a[0] - b[0];
      });

      this.charts.chart2.chartData = headers.concat(data);
    },
    drawChart3: function(response) {
      this.charts.chart3.loading = false;

      if (response.length === 0) return;

      let headers = [["Date", "Sales"]];

      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      this.charts.chart3.rawData = response;

      let data = [
        ...response.map(item => {
          return [new Date(item.yymmdd), parseFloat(item[metric] || 0)];
        })
      ];

      this.charts.chart3.chartData = headers.concat(data);
    },
    onChart3TableMount() {
      if (this.selectedMetric().id === "sold") {
        this.chart3Table = tables.chart3Table;

        this.chart3Table.dataSet = this.charts.chart3.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          "$ Sold": i.sales
        }));
      }
      if (this.selectedMetric().id === "bottles") {
        this.chart3Table = tables.chart3TableBottles;

        this.chart3Table.dataSet = this.charts.chart3.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Bottles: i.bottles
        }));
      }
      if (this.selectedMetric().id === "alc_amount") {
        this.chart3Table = tables.chart3TableAlcAmount;

        this.chart3Table.dataSet = this.charts.chart3.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Amount: i.alc_amount
        }));
      }
    },
    updateChart3Table() {
      let self = this;

      let columnName = "",
        columnTitle = "";

      if (this.selectedMetric().id === "sold") {
        columnTitle = "$ Sold";
        columnName = "sales";
      }
      if (this.selectedMetric().id === "bottles") {
        columnTitle = "Bottles";
        columnName = "bottles";
      }
      if (this.selectedMetric().id === "alc_amount") {
        columnTitle = "Amount";
        columnName = "alc_amount";
      }

      if (!this.chart3Table.groupByYearsView) {
        this.chart3Table.options.columns = ["Year", "Month", columnTitle];
        this.chart3Table.options.filterable = ["Year", "Month", columnTitle];

        this.chart3Table.dataSet = this.charts.chart3.rawData.map(i => {
          let o = {
            Year: i.yy,
            Month: i.mm
          };
          o[columnTitle] = i[columnName];

          return o;
        });
      } else {
        let currentMonth = parseInt(moment().format("M"));

        this.chart3Table.options.columns = ["Year", columnTitle];
        this.chart3Table.options.filterable = ["Year", columnTitle];

        let groupedData = [];

        this.charts.chart3.rawData
          .filter(
            d =>
              parseInt(moment(d.yymmdd).format("M")) <=
              (self.chart3Table.YTD
                ? currentMonth
                : moment(d.yymmdd).format("M"))
          )
          .forEach(t => {
            let yy = groupedData.find(g => g["Year"] === t.yy);

            if (!yy) {
              let i = {
                Year: t.yy
              };

              i[columnTitle] = parseFloat(t[columnName]);
              groupedData.push(i);
            } else {
              yy[columnTitle] += parseFloat(t[columnName]);
            }
          });

        this.chart3Table.dataSet = groupedData;
      }
    },
    onTotalSalesReady(payload) {
      this.charts.chart3.googleRef = payload.chart;
    },
    onChart3ZoomClick(months) {
      this.charts.chart3.googleRef.setVisibleChartRange(
        moment()
          .subtract(months, "month")
          .toDate(),
        moment().toDate()
      );
    },
    drawChart4: function(response) {
      this.charts.chart4.loading = false;

      if (response.length === 0) return;

      this.charts.chart4.rawData = response;

      this.updateChart4();
    },
    updateChart4() {
      //debugger

      let filter = this.$refs.filteringPanel.selected;
      this.charts.chart4.subtitle = `Sales in the period [${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}]`;

      let headers = [
        [
          "Market",
          "$ Sold",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      let metric = undefined;

      metric = "sales";

      let data = [
        ...this.charts.chart4.rawData.map(item => {
          const tooltip = `Market: ${item.market}\nTotal sales $: ${parseFloat(
            item["sales"]
          ).toFixed(2)}\nBottles: ${parseFloat(item["bottles"]).toFixed(
            2
          )}\nAlc. amount: ${parseFloat(item["alc_amount"]).toFixed(2)}`;

          return [`${item.market}`, parseFloat(item[metric] || 0), tooltip];
        })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart4.pagination.total = data.length;
      data = data.slice(
        this.charts.chart4.pagination.value * 10 - 10,
        this.charts.chart4.pagination.value * 10
      );

      this.charts.chart4.chartData = headers.concat(data);
    },
    onChart4TableMount() {
      this.chart4Table.dataSet = this.charts.chart4.rawData.map(function(i) {
        let obj = {};

        obj["Market"] = i.market;
        obj["$ Sold"] = i["sales"];
        obj["Bottles"] = parseFloat(i["bottles"]);
        obj["Amount of alcohol"] = parseFloat(i["alc_amount"] || 0);

        return obj;
      });
    },

    drawChart5: function(response) {
      this.charts.chart5.loading = false;

      if (response.length === 0) return;

      this.charts.chart5.rawData = response;

      this.updateChart5();
    },
    updateChart5() {
      //debugger
      let filter = this.$refs.filteringPanel.selected;
      this.charts.chart5.subtitle = `Sales in the period [${moment(
        filter.period.startDate
      ).format("YYYY-MM-DD")} - ${moment(filter.period.endDate).format(
        "YYYY-MM-DD"
      )}]`;

      let headers = [
        [
          "Account",
          "$ Sold",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      let metric = undefined;

      metric = "sales";

      let data = [
        ...this.charts.chart5.rawData.map(item => {
          const tooltip = `Account: ${
            item.accountname
          }\nTotal sales $: ${parseFloat(item["sales"]).toFixed(
            2
          )}\nBottles: ${parseFloat(item["bottles"]).toFixed(
            2
          )}\nAlc. amount: ${parseFloat(item["alc_amount"]).toFixed(2)}`;

          return [
            `${item.accountname}`,
            parseFloat(item[metric] || 0),
            tooltip
          ];
        })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart5.pagination.total = data.length;
      data = data.slice(
        this.charts.chart5.pagination.value * 10 - 10,
        this.charts.chart5.pagination.value * 10
      );

      this.charts.chart5.chartData = headers.concat(data);
    },
    onChart5TableMount() {
      this.chart5Table.dataSet = this.charts.chart5.rawData.map(function(i) {
        let obj = {};

        obj["Account"] = i.accountname;
        obj["$ Sold"] = i["sales"];
        obj["Bottles"] = parseFloat(i["bottles"]);
        obj["Amount of alcohol"] = parseFloat(i["alc_amount"] || 0);

        return obj;
      });
    }
  }
};
</script>

<style scoped></style>
