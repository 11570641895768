export default {
  chart1Table: {
    name: "custom_table_wh_product_details_chart1",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  },
  /*
  chart3Table: {
    name: "custom_table_wh_product_details_chart3",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  },
  */

  chart3Table: {
    name: "custom_table_wh_product_details_chart3",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "$ Sold"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      filterable: ["Year", "Month", "$ Sold"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "$ Sold",
          type: "number",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ]
    }
  },
  chart3TableBottles: {
    name: "custom_table_wh_product_details_chart3",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "Bottles"],
      totalColumns: [
        {
          name: "Bottles",
          alias: "Bottles",
          style: {
            style: "decimal"
          }
        }
      ],
      filterable: ["Year", "Month", "Bottles"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "Bottles",
          type: "number",
          style: {
            style: "decimal"
          }
        }
      ]
    }
  },
  chart3TableAlcAmount: {
    name: "custom_table_wh_product_details_chart3",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "Amount"],
      totalColumns: [
        {
          name: "Amount",
          alias: "Amount",
          style: {
            style: "decimal"
          }
        }
      ],
      filterable: ["Year", "Month", "Amount"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "Amount",
          type: "number",
          style: {
            style: "decimal"
          }
        }
      ]
    }
  },
  chart4Table: {
    name: "custom_table_wh_product_details_chart4",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  },
  chart5Table: {
    name: "custom_table_wh_product_details_chart5",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Account", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  }
};
